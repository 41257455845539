import { Amplify, Hub } from 'aws-amplify'
import {
  hubSignUp,
  hubSignUpFailure,
  hubResendSignUp,
  hubResendSignUpFailure,
} from '../scripts/auth/sign-up'
import { hubSignIn, hubSignInFailure } from '../scripts/auth/sign-in'
import { datadogLogs } from '@datadog/browser-logs'

import {
  hubForgotPassword,
  hubForgotPasswordFailure,
  hubForgotPasswordSubmit,
  hubForgotPasswordSubmitFailure,
} from '../scripts/auth/reset'

const stage = !Pura?.environment?.production
const logoutUrl = stage
  ? `https://staging-pura.auth.us-east-1.amazoncognito.com/logout?client_id=6n43s10do1victjnk4sg9j7sb4&logout_uri=https://staging-pura-scents.myshopify.com/account/logout`
  : `https://auth.pura.co/logout?client_id=4iekubat0jb5iljfbaalsiqf9j&logout_uri=https://pura.com/account/logout`

Amplify.configure({
  Auth: {
    identityPoolId: stage
      ? 'us-east-1:6453fc73-c338-4dc0-88d4-97c3d94560df'
      : 'us-east-1:f277ac0f-2758-4647-9e45-5fb6c5799200',
    region: 'us-east-1',
    userPoolId: stage ? 'us-east-1_gTzleiu29' : 'us-east-1_LaB718hYv',
    userPoolWebClientId: stage
      ? '6n43s10do1victjnk4sg9j7sb4'
      : '4iekubat0jb5iljfbaalsiqf9j',
    mandatorySignIn: false,
    oauth: {
      domain: stage
        ? 'staging-pura.auth.us-east-1.amazoncognito.com'
        : 'auth.pura.co',
      scope: ['openid', 'profile', 'email'],
      redirectSignIn: `https://${Pura.shop_domain}/account/login?redirect`,
      redirectSignOut: `https://${Pura.shop_domain}/account/logout`,
      clientId: stage
        ? '6n43s10do1victjnk4sg9j7sb4'
        : '4iekubat0jb5iljfbaalsiqf9j',
      responseType: 'code',
    },
    authenticationFlowType: 'USER_SRP_AUTH',
  },
})

import '../scripts/auth/activate'
import '../scripts/auth/check-migration'
import '../scripts/auth/federated-sign-in'
import '../scripts/auth/sign-out'
import '../scripts/auth/data-compliance-forms'

Hub.listen('auth', ({ payload }) => {
  const message = payload?.data?.message
  const event = payload.event
  const data = payload.data

  switch (event) {
    case 'signIn':
      hubSignIn(data)
      break

    case 'signIn_failure':
      hubSignInFailure(message)
      break

    case 'signUp':
      hubSignUp()
      break

    case 'signUp_failure':
      hubSignUpFailure(message)
      break

    case 'resendSignUp':
      hubResendSignUp(data)
      break

    case 'resendSignUp_failure':
      hubResendSignUpFailure(message)
      break

    case 'forgotPassword':
      hubForgotPassword()
      break

    case 'forgotPassword_failure':
      hubForgotPasswordFailure(message)
      break

    case 'forgotPasswordSubmit':
      hubForgotPasswordSubmit()
      break

    case 'forgotPasswordSubmit_failure':
      hubForgotPasswordSubmitFailure(message)
      break

    case 'signOut':
      window.location.href = logoutUrl
      break

    case 'oAuthSignOut':
      window.location.href = logoutUrl
      break

    case 'customState_failure':
      datadogLogs.logger.info('Google OAuth Sign in Failed', {
        name: 'google sign in',
      })
      window.location.href = logoutUrl
      break

    default:
  }
})
