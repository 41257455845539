import $ from 'cash-dom'
import { Auth } from 'aws-amplify'
import { getCookie, setCookie } from '../utilities/cookies'
import { createFormStateManager } from '../utilities/form-manager'
import { checkMigration } from './check-migration'
import { hubSignOut } from './sign-out'

const signInFormManager = createFormStateManager({
  formSelector: '#email-sign-in-form',
  defaultText: 'Sign In',
})

const baseUrl = !window.Pura?.environment?.production
  ? 'https://staging.trypura.io/mobile/api'
  : 'https://trypura.io/mobile/api'

async function callMultipass(
  cognitoToken: string,
  return_to_url: string,
  maxRetries = 3
) {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await fetch(`${baseUrl}/multipass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: cognitoToken,
        },
        body: JSON.stringify({ cognito_token: cognitoToken, return_to_url }),
      })

      if (!response.ok) {
        throw new Error(`Error signing in`)
      }

      const result = await response.json()
      if (result.error) {
        throw new Error('Error signing in')
      }

      return result
    } catch (error) {
      if (attempt < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
      } else {
        await hubSignOut()
        throw error
      }
    }
  }
}

const hubSignInFailure = (message: string) => {
  signInFormManager.setFailure(message)
}

const hubSignIn = async (data: any) => {
  try {
    const return_to_url = getCookie('login_redirect') || '/'
    const idToken = data?.signInUserSession?.idToken || {}

    const cognitoToken = idToken?.jwtToken
    const cognitoEmail = idToken?.payload?.email
    const cognitoFirebaseID = idToken?.payload?.['custom:firebase_uid']

    if (!cognitoToken) {
      console.error('No JWT token')
      return
    }

    if (window.braze) {
      window.braze?.getUser().setEmail(cognitoEmail)
      window.braze?.changeUser(cognitoFirebaseID)
    }

    const result = await callMultipass(cognitoToken, return_to_url)
    signInFormManager.setVerified()
    window.location.href = result.signin_url
  } catch (error) {
    signInFormManager.setFailure(error.message)
  }
}

const emailSignIn = async (email: string, password: string): Promise<void> => {
  try {
    await Auth.signIn(email, password)
  } catch (e: any) {
    if (e.code === 'UserNotConfirmedException') {
      if (window.Pura?.accountPanel) {
        console.log('User not confirmed, showing verify panel.')
        window.Pura.accountPanel.showVerifyPanel(email, password)
      }
      return
    }

    try {
      const migrationRes = await checkMigration(email, password)
      const migrationData = await migrationRes.json()

      if (migrationData.retry) {
        await Auth.signIn(email, password)
      }
    } catch (migrationError) {
      console.error('Migration error:', migrationError)
    }
  }
}

$('#email-sign-in-form').on('submit', (e) => {
  e.preventDefault()
  signInFormManager.setLoading('Logging in...')

  const form = $(e.target)
  const email = (form.find('#signin-email').val() as string).trim()
  const password = form.find('#signin-password').val() as string

  emailSignIn(email, password)
  setCookie('login_redirect', location.href, 1)
})

export { emailSignIn, hubSignIn, hubSignInFailure }
